<template>
  <main>
    <div class="relative red-gray-800">
      <div class="absolute inset-0">
        <img class="hidden lg:block object-cover object-top w-full h-full"
             src="../assets/contact_header.webp" width="1280" height="675" alt="lassen">

        <img class="block lg:hidden object-cover object-top w-full h-full"
             src="../assets/contact_header.webp" width="320" height="196" alt="lassen">

        <div class="absolute inset-0 bg-gray-800" style="mix-blend-mode: hard-light;" aria-hidden="true"></div>
      </div>
      <div class="relative py-24 px-4 mx-auto max-w-7xl sm:py-32 sm:px-6 lg:px-8">
        <h1 class="text-3xl font-medium tracking-tight text-white sm:text-5xl lg:text-6xl">Contact
        </h1>
        <p class="mt-6 max-w-3xl text-base text-gray-300 ">
          Neem vrijblijvend contact op met ons voor meer informatie, wij staan altijd klaar om u verder te helpen op het gebied van machines, onderdelen, lassen en nog veel meer.
        </p>
      </div>
    </div>

    <div class="relative bg-white">
      <div class="absolute inset-0">
        <div class="absolute inset-y-0 left-0 w-1/2 bg-gray-50"></div>
      </div>
      <div class="relative mx-auto max-w-7xl lg:grid lg:grid-cols-5">
        <div class="py-16 px-4 bg-gray-50 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
          <div class="mx-auto max-w-lg">
            <h2 class="text-2xl font-medium tracking-tight lg:text-3xl ">
              Stuur een bericht
            </h2>

            <p class="mt-3 text-mdleading-6 text-gray-500 ">
              Stuur ons gerust een bericht, wij zullen deze zo spoedig mogelijk beantwoorden.
            </p>
            <dl class="mt-8 text-base text-gray-500">
              <div class="">
                <dt class="sr-only">Bezoekadres</dt>
                <dd class="cursor-pointer hover:text-gray-900" @click="redirectRoute">
                  <p class="">Galileistraat 13</p>
                  <p class="">7701 SK, Dedemsvaart</p>
                </dd>
              </div>
              <div class="mt-6">
                <dt class="sr-only">Telefoon</dt>
                <dd class="flex">
                  <!-- Heroicon name: outline/phone -->
                  <svg class="flex-shrink-0 w-6 h-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"/>
                  </svg>
                  <span class="ml-3 hover:text-gray-900">
                    <a href="tel:0546787754">0523-785754</a>
                  </span>
                </dd>
              </div>
              <div class="mt-3">
                <dt class="sr-only">Email</dt>
                <dd class="flex">
                  <!-- Heroicon name: outline/mail -->
                  <svg class="flex-shrink-0 w-6 h-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"/>
                  </svg>
                  <span class="ml-3 hover:text-gray-900">
                    <a href="mailto:info@jclasmontagetechniek.nl?subject=Contact via JC Las & Montagetechniek">info@jclasmontagetechniek.nl</a>
                  </span>
                </dd>
              </div>
            </dl>
          </div>
        </div>
        <div class="py-16 px-4 bg-white sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
          <div class="mx-auto max-w-lg lg:max-w-none">
            <form @submit.prevent="send" method="post" class="grid grid-cols-1 gap-y-6">
              <div>
                <label for="name" class="sr-only">Naam</label>
                <input type="text" v-model="form.name" name="name" id="name" autocomplete="name" class="block  py-3 px-4 w-full placeholder-gray-500 rounded-sm border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500" placeholder="Naam">
              </div>
              <div>
                <label for="email" class="sr-only">Email</label>
                <input id="email" name="email" v-model="form.email" type="email" autocomplete="email"
                       class="block py-3 px-4 w-full placeholder-gray-500 rounded-sm border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                       :class="{'border-red-900' : emailError }"
                       placeholder="Email">
              </div>
              <div>
                <label for="phone" class="sr-only">Telefoon</label>
                <input type="text" v-model="form.phone" name="phone" id="phone" autocomplete="tel" class="block  py-3 px-4 w-full placeholder-gray-500 rounded-sm border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500" placeholder="Telefoon">
              </div>
              <div>
                <label for="Message" class="sr-only">Bericht</label>
                <textarea id="Message" v-model="form.message" name="Message" rows="4" class="block  py-3 px-4 w-full placeholder-gray-500 rounded-sm border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500" placeholder="Uw bericht"></textarea>
              </div>
              <div class="lg:text-lg" :class="{'text-red-900' : error }"> {{ message ? message : '' }}</div>
              <div>
                <button :disabled="success" type="submit" class="inline-flex justify-center py-3 px-6 text-base font-medium rounded-sm border border-gray-800 hover:bg-gray-50">
                  {{ success ? 'Verzonden' : 'Verzenden' }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </main>

</template>

<script>
import axios from 'axios';

export default {
  name : 'Contact',

  data : () => {
    return {
      error      : false,
      success    : false,
      message    : '',
      emailError : false,
      form       : {
        name    : '',
        email   : '',
        phone   : '',
        message : ''
      }
    }
  },

  methods : {
    send() {
      let config = {
        headers : {
          'X-Requested-With' : 'XMLHttpRequest'
        }
      };

      let form = new FormData();
      form.set('name', this.form.name);
      form.set('email', this.form.email);
      form.set('phone', this.form.phone);
      form.set('message', this.form.message);


      axios.post('/send.php', form, config).then((res) => {
        this.message = '';
        this.message = res.data.message;
        this.success = true;
        this.clearForm();

      }).catch(err => {
        this.message = '';
        this.error   = true;

        let response = err.response.data;

        if (response.type === 'email') {
          this.emailError = true;
        }
        this.message = response.message;

      });
    },

    clearForm() {
      this.form.name    = '';
      this.form.email   = '';
      this.form.phone   = '';
      this.form.message = '';
    },

    redirectRoute(){
      window.open('https://g.page/jc-las-montage-techniek?share', '_blank');
    }
  },

  metaInfo : {
    title : 'Contact',
    meta  : [
      {
        name    : 'description',
        content : 'Neem vrijblijvend contact op met ons voor meer informatie, wij helpen u graag verder.'
      }
    ]
  }
}
</script>
